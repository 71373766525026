(function () {
    'use strict';

    var mexicoGeoId = 'ChIJU1NoiDs6BIQREZgJa760ZO0';
    var map;
    var markers = [];

    Handlebars.registerHelper('assetsUrl', function(block) {
        return window.assetsUrl;
    });

    function getLocations () {
        return $.getJSON('data/locations.json');
    }

    function mapsInitialized () {
        var defer = $.Deferred();
        window.initMap = function () {
            defer.resolve();
        };
        return defer.promise();
    }

    function buildMap (params) {
        var locations = params[0];

        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({placeId: mexicoGeoId}, function (searchResults) {
            var mapCenterLocation = searchResults[0];

            map = new google.maps.Map(document.getElementById('map-container'), {
                center: { lat: 18.606166, lng: -90.7809082 },
                zoom: 5,
                styles: globals.mapStyle,
                disableDefaultUI: true
            });

            var clusterOptions = {
                styles: [{
                    url: 'img/marker-cluster.png',
                    width: 85,
                    height: 100,
                    textSize: 13,
                    textColor: '#8b8b8b',
                    fontWeight: 'bold'
                }]
            };

            locations.forEach(function (location) {
                var latLng = new google.maps.LatLng(location.position.lat, location.position.lng);
                var marker = new google.maps.Marker({position: latLng, icon: window.assetsUrl + 'img/marker-single.png'});
                google.maps.event.addListener(marker, 'click', function () {
                    closeTooltips();
                    new CustomMarker(marker.getPosition(), map, location);
                    marker.setIcon(window.assetsUrl + 'img/marker-active.png');
                    map.setCenter(marker.getPosition());
                    map.panBy(250, 0);
                });
                markers.push(marker);
            });
            var markerCluster = new MarkerClusterer(map, markers, clusterOptions);

            map.addListener('click', closeTooltips);
        });

        function CustomMarker(latlng, map, args) {
            this.latlng = latlng;
            this.args = args;
            this.setMap(map);
        }
        CustomMarker.prototype = new google.maps.OverlayView();
        CustomMarker.prototype.draw = function () {
            if(!this.overlayElem) {
                this.overlayElem = $(templates.tooltip(this.args));
                this.getPanes().overlayImage.appendChild(this.overlayElem[0]);
            }

            var point = this.getProjection().fromLatLngToDivPixel(this.latlng);
            if (point) {
                this.overlayElem.css({
                    left: (point.x) + 'px',
                    top: (point.y) + 'px'
                });
            }

            this.overlayElem.find('.details-button').on('click', function () {
                showDetails(this.args);
            }.bind(this));
        };
        CustomMarker.prototype.getPosition = function() {
            return this.latlng;
        };
    }

    function closeTooltips () {
        $('.location-tooltip').remove();

        markers.forEach(function (marker) {
            marker.setIcon(window.assetsUrl + 'img/marker-single.png');
        });
    }

    $.when(getLocations(), mapsInitialized()).done(buildMap);

    function showDetails (location) {
        var $details = $(templates.details(location));
        $('#app-container').append($details);

        /*if(history && history.pushState) {
            history.pushState(null, null, document.baseURI + location.slug);
        }
        $details.find('[data-close-overlay]').one('click', function () {
            history.pushState(null, null, document.baseURI);
        });*/
    }

    function showDetailsBySlug(slug) {
        getLocations().then(function (locations) {
            var location = false;

            for (var i = 0; i < locations.length; i++) {
                if (locations[i].slug == slug) location = locations[i];
            }

            if(location) {
                showDetails(location);
            }
        });
    }

    //open by URI
    function getBaseURI () {
        if ('baseURI' in document) {
            return document.baseURI;
        }
        else {
            var baseTags = document.getElementsByTagName ("base");
            if (baseTags.length > 0) {
                return baseTags[0].href;
            }
            else {
                return window.location.href;
            }
        }
    }

    var slug = location.href.replace(getBaseURI(), '');
    if(slug) {
        showDetailsBySlug(slug);
    }
    else {
        openInitialOverlay();
    }

    function openInitialOverlay () {
        var $initial = $(templates.initial());
        $('#app-container').append($initial);
    }

    //select
    getLocations().then(function (locations) {
        var orderedLocations = [];
        for (var i = 0; i < locations.length; i++) {
            var alreadyOrdered = false;
            for (var j = 0; j < orderedLocations.length; j++) {
                if (orderedLocations[j].country == locations[i].address.country) {
                    orderedLocations[j].locations.push(locations[i]);
                    alreadyOrdered = true;
                }
            }

            if (!alreadyOrdered) {
                var locationData = {
                    'country': locations[i].address.country,
                    'locations': []
                };
                locationData.locations.push(locations[i]);
                orderedLocations.push(locationData);
            }
        }

        orderedLocations.sort(function (a, b) {
            if (a.country === b.country) {
                return 0;
            }
            else {
                return (a.country < b.country) ? -1 : 1;
            }
        });
        for (var k = 0; k < orderedLocations.length; k++) {
            orderedLocations[k].locations.sort(function (a, b) {
                if (a.dropdownName === b.dropdownName) {
                    return 0;
                }
                else {
                    return (a.dropdownName < b.dropdownName) ? -1 : 1;
                }
            });
        }

        var $selectContainer = $(templates['select-container']({locations: orderedLocations}));
        $('#select-container').append($selectContainer);
        $('#select-container').find('select').on('change', function () {
            $('[data-overlay]').remove();
            var selection = $(this).val();
            showDetailsBySlug(selection);
        });
    });

})();
